<template>
  <el-dialog
    title="分班选择"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="70%"
  >
    <div class="margin-tb">
      <div class="flex justify-content-between">
        <el-button
          class="margin-tb-sm"
          type="primary"
          style="background-color: #2fa4e7"
          size="mini"
          @click="goNav('/educationCenter/newClass')"
          >新建班级</el-button
        >
        <el-col :span="6">
          <el-input
            v-model="listQueryStudent.KeyWord"
            placeholder="请输入班级名称或教师名称搜索"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </div>

      <div style="margin-top: 10px">
        <as-list
          :ajaxMethods="{ list: 'getClassList' }"
          :listQueryProp="listQueryStudent"
          @SelectionChange="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            prop="class_name"
            label="班级名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="school_name"
            label="校区"
          ></el-table-column>
          <el-table-column align="center" label="教师" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.Tlistname.length">
                <span
                  v-for="(item, index) in scope.row.Tlistname"
                  :key="index"
                  class="cursor"
                >
                  {{ item }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="开班时间">
            <!-- <template slot-scope="scope">
              <span class="cursor">
                {{ scope.row.starttime.split("T")[0] }}</span
              >
            </template> -->
            <template slot-scope="scope">
              <span v-if="scope.row.starttime" class="cursor">
                {{ scope.row.starttime.split("T")[0] }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="class_count"
            label="人数"
          >
           <template slot-scope="scope">
             <div v-if="scope.row.class_count"><span>{{ scope.row.class_count}}</span><span>/</span><span>{{ scope.row.class_num}}</span></div>
             <div v-else><span>-</span></div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="to_class_time" label="上课时间">
          </el-table-column>
        </as-list>
      </div>
      <div class="flex justify-content-flexEnd">
        <el-button type="primary" @click="Submit">确定</el-button>
        <el-button class="myClient-left-30" @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import AsList from "@/components/as-list";
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
    classesId: {
      type: Number,
      default: 0,
    },
    curriculumId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    AsList,
  },
  data() {
    return {
      multipleSelection: [],
      listQueryStudent: {
        curriculum_id: [],
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
      Division: {
        classes_id: [],
        order_curriculum_id: "",
      },
      dialogVisible: false,
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
    classesId(val) {
      this.listQueryStudent.curriculum_id = [];
      this.Division.order_curriculum_id = val;

      this.listQueryStudent.curriculum_id.push(val);
    },
    curriculumId(val) {
      this.listQueryStudent.curriculum_id = [];  
      this.listQueryStudent.curriculum_id.push(val);
    },
  },
  created() {},
  methods: {
    Submit() {
      //   StudentDivision
      if (this.Division.classes_id) {
        this.API.StudentDivision([this.Division]).then((res) => {
          if (res.success) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.close();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message({ message: "请选择班级后再尝试！", type: "warning" });
      }
    },
    goNav(url) {
      this.$router.push(url);
    },
    close() {
      this.$emit("closepop");
      this.$emit("RefreshTheCourse");
    },

    handleSelectionChange(val) {
      this.Division.classes_id = [];
      this.multipleSelection = val;
      for (let index = 0; index < this.multipleSelection.length; index++) {
        this.Division.classes_id.push(this.multipleSelection[index].id);
      }
      // this.Division.classes_id=
      console.log(this.Division.classes_id);
    },
    // 选中行
    // singleSelect(e) {
    //   this.Division.classes_id = e.id;
    //   console.log(e);
    // },
  },
};
</script>

<style scoped>
>>> .el-table__body tr.current-row > td {
  background-color: #2fa4e7 !important;
  color: #fff;
}
.class-tag {
  border-radius: 4px;
  border: 1px solid #4a9efc;
  padding: 2px 4px;
  background-color: #dbebfe;
  color: #4a9efc;
}
</style>
