<template>
  <div>
    <div class="width-100 flex justify-content-between" style="">
      <div
        class="el-tabs__nav-scroll"
        style="width: 100%; position: absolute; height: 40px"
      >
        <span style="margin-left: 20px; line-height: 40px; color: #37a7e7"
          >账户剩余：<label
            style="color: red; font-weight: bold; padding: 0px 5px"
            >{{ stuForm.balance }}</label
          >元</span
        >
      </div>

      <div style="width: 350px; margin: auto; margin-top: 20px"></div>
      <div style="line-height: 40px; padding-right: 20px">
        <span class="cursor" @click="creatStudents">编辑</span
        ><span class="border-right" style="margin: 0px 5px 0px 5px" /><span
          class="cursor"
          @click="delstudent"
          >删除</span
        >
      </div>
    </div>

    <div
      class="background-f"
      style="margin-top: 10px; padding: 10px 10px 10px 20px"
    >
      <ul v-if="isshow" class="width-100 flex flex-wrap-wrap">
        <li style="width: 25%">
          <div class="myClient_client_img1 margin-auto">
            <img v-if="stuForm.avatar" :src="stuForm.avatar | previewImage" />
            <img v-else src="../../../assets/images/portrait/default.png" />
          </div>
          <div class="margin-auto text-align cursor">
            <span class="text-name">{{ stuForm.FullName }}</span
            ><span class="text-phone"
              >({{ stuForm.relation_name }}{{ stuForm.phone }})</span
            >
          </div>
        </li>
        <li style="width: 70%; color: #ccc; line-height: 25px">
          <ul class="width-100 flex flex-wrap-wrap">
            <li
              class="width-100 flex flex-wrap-wrap"
              v-for="(item, index) in student"
              :key="index"
              style="width: 25%; color: #ccc; line-height: 25px"
            >
              <div class="width-100" style="display: flex; flex-wrap: wrap">
                <span
                  style="
                    width: 70px;
                    text-align-last: justify;
                    margin-right: 10px;
                    color: #000;
                  "
                  >{{ item.name }}</span
                >
                <span
                  v-if="item.field == 'birthday' && stuForm[item.field]"
                  class="color-black"
                  style="margin-left: 10px; color: #808080"
                  >{{ stuForm[item.field].split("T")[0] }}</span
                >
                <span
                  v-else
                  class="color-black"
                  style="margin-left: 10px; color: #808080"
                  >{{ stuForm[item.field] }}</span
                >
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div
        v-show="!isshow"
        v-if="!isshow"
        class="more-info flex aligin-items-center justify-content-center text-blue padding-xs bg-z cursor"
        @click="isshow = !isshow"
      >
        <span>展开学员信息</span>
        <i class="el-icon-caret-bottom margin-left-sm"></i>
      </div>
      <div
        v-else
        class="more-info flex aligin-items-center justify-content-center text-blue padding-xs bg-z cursor"
        @click="isshow = !isshow"
      >
        <span>收起学员信息</span>
        <i class="el-icon-caret-bottom margin-left-sm"></i>
      </div>
    </div>
    <div class="background-f padding">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="订单" name="order">
          <div style="margin-top: 10px">
            <div class="margin-sm">
              <span>
                当前结果：总计{{ OrderList.length }}条记录，应收款项总计：{{
                  receivable
                }}&nbsp;|&nbsp;实收总计：{{
                  netReceipts
                }}&nbsp;|&nbsp;&nbsp;欠费：{{ Arrears }}</span
              >
            </div>
            <as-list ref="OrderList" :initdataList="OrderList">
              <el-table-column
                align="center"
                prop="id"
                sortable
                label="订单号"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="order.phone"
                label="联系方式"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="type_name"
                label="订单类型"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="order.order_title"
                label="交易内容"
              ></el-table-column>
              <el-table-column align="center" label="应收/应退">
                <template slot-scope="scope">
                  <div v-if="scope.row.refund_money > 0">
                    <span class="lesson-tag">退</span
                    ><span> {{ scope.row.refund_money }}</span>
                  </div>
                  <div v-else>
                    <span class="lesson-tag">收</span>
                    <span>{{ scope.row.pay_model_price }} </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="pay_money"
                label="实收/实退"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.refund_money > 0">
                    <span class="lesson-tag">退</span
                    ><span> {{ scope.row.refund_money }}</span>
                  </div>
                  <div v-else>
                    <span class="lesson-tag">收</span>
                    <span>{{ scope.row.pay_money }} </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="arrears_money"
                label="欠费"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="order.order_tags"
                label="订单标签"
              ></el-table-column>

              <el-table-column
                align="center"
                prop="order.handle_school_name"
                label="经办校区"
              >
              </el-table-column>

              <el-table-column
                align="center"
                prop="order.add_user_name"
                label="经办人"
              ></el-table-column>

              <el-table-column align="center" sortable label="经办日期">
                <template slot-scope="scope">
                  <span v-if="scope.row.order.handle_date" class="cursor">
                    {{ scope.row.order.handle_date.split("T")[0] }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="order.order_in_remark"
                label="对内备注"
              >
              </el-table-column>
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-dropdown trigger="hover">
                    <label class="cursor">
                      操作
                      <i class="el-icon-arrow-down el-icon-caret-bottom"></i>
                    </label>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <span @click="goSignUp(scope.row)">编辑</span>
                      </el-dropdown-item>
                      <!-- <el-dropdown-item>打印</el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </as-list>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课程" v-if="OrderList.length" name="curriculum">
          <div v-for="(item, index) in OrderList" :key="index">
            <div
              class="flex padding-sm width-100"
              style="border-bottom: 1px solid #eee"
            >
              <div class="flex width-100">
                <div class="margin-auto flex" style="width: 200px">
                  <div>
                    <span class="class-tag margin-sm" v-if="item.curriculum.tmode == 0"
                      >课</span
                    >
                    <!-- <i class="iconfont icon-dui" ></i> -->
                    <svg v-else class="icon" aria-hidden="true">
                      <use xlink:href="#icon-dui"></use>
                    </svg>
                    <span
                      class="class-tag margin-sm"
                      v-if="item.curriculum.charge_type == 0"
                      >未知</span
                    >
                    <span
                      class="class-tag margin-sm"
                      v-if="item.curriculum.charge_type == 1"
                      >按课时</span
                    >
                    <span
                      class="class-tag margin-sm"
                      v-if="item.curriculum.charge_type == 2"
                      >按时间</span
                    >
                    <span
                      class="class-tag margin-sm"
                      v-if="item.curriculum.charge_type == 3"
                      >按期</span
                    >
                  </div>
                </div>
                <div>
                  <div class="class_name">
                    <span class="class_boid">{{
                      item.curriculum.curriculum_name
                    }}</span>
                  </div>
                  <div>
                    <span>校区：{{ item.order.school_name }}</span>
                  </div>
                </div>
                <div class="margin-auto">
                  <span class="border-right" style="margin: 0px 5px 0px 5px" />
                  <span class="text-sheng">升</span>
                </div>
                <div
                  class="flex width-100 justify-content-flexEnd"
                  style="height: 30px; margin: auto"
                >
                  <!-- <el-button size="mini" @click="goSignUp(item)"
                    >续费</el-button
                  >
                  <el-button size="mini" @click="gotransferCourses(item)"
                    >转课</el-button
                  > -->
                  <el-button size="mini" @click="editHandle('StopCourse', item)"
                    >停课</el-button
                  >
                  <el-button size="mini" @click="gorefund(item)"
                    >退费</el-button
                  >
                  <el-button
                    size="mini"
                    @click="editHandle('ClassEnding', item)"
                    >结课</el-button
                  >
                  <el-button
                    size="mini"
                    @click="editHandle('Division', item.id, item)"
                    >分班</el-button
                  >
                </div>
              </div>
            </div>
            <div class="flex width-100">
              <div class="" style="width: 30%" v-if="OrderList.length">
                <div class="margin">
                  <div>
                    <span style="color: #ccc">报名时间： </span
                    ><span>{{ item.add_time.split("T")[0] }}</span>
                  </div>
                  <div>
                    <span style="color: #ccc">剩余课时：</span
                    ><span
                      >{{ item.over_hours }}(总计{{ item.total_num }})</span
                    >
                    <!-- <span>设置有效期</span> -->
                  </div>
                  <div>
                    <span style="color: #ccc">剩余学费：</span
                    ><span
                      >{{ item.over_money }}(总计{{ item.total_money }})</span
                    >
                  </div>
                </div>
              </div>
              <div style="width: 70%">
                <div style="border-left: 1px solid rgb(238, 238, 238)">
                  <div class="margin" style="margin-top: 10px">
                    <div
                      class="el-table el-table--fit el-table--scrollable-x el-table--enable-row-hover el-table--enable-row-transition"
                    >
                      <div class="el-table__header-wrapper">
                        <!-- :data="OrderList.filter((t) => t.id === item.id).classes_list" -->
                        <el-table
                          :data="item.classes_list"
                          border
                          style="width: 100%"
                        >
                          <el-table-column
                            prop="status_name"
                            label="状态"
                            width="180"
                          >
                            <!-- <template slot-scope="scope">
                              <div
                                v-for="(item, index) in CourseStatus"
                                :key="index"
                              >
                                <span v-if="scope.row.status == item.Key">
                                  {{ item.Name }}</span
                                >
                              </div>
                            </template> -->
                          </el-table-column>
                          <el-table-column
                            label="班级名称"
                            prop="class_name"
                            width="180"
                          >
                          </el-table-column>
                          <el-table-column prop="teacher_name" label="教师">
                            <!-- <template slot-scope="scope">
                              <div
                                v-if="
                                  scope.row.classes &&
                                  scope.row.classes.Tlistname.length
                                "
                              >
                                <span
                                  v-for="(item, index) in scope.row.classes
                                    .Tlistname"
                                  :key="index"
                                  class="cursor"
                                >
                                  {{ item }}</span
                                >
                              </div>
                            </template> -->
                          </el-table-column>
                          <el-table-column prop="handle_date" label="开班日期">
                            <template slot-scope="scope">
                              <div v-if="scope.row.handle_date">
                                <span>{{
                                  scope.row.handle_date.split("T")[0]
                                }}</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            align="center"
                            prop="add_time"
                            sortable
                            label="经办日期"
                          >
                            <template slot-scope="scope">
                              <div v-if="scope.row.add_time">
                                <span>{{
                                  scope.row.add_time.split("T")[0]
                                }}</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column prop="used_hours" label="已用课时">
                          </el-table-column>
                        </el-table>
                      </div>

                      <div
                        class="el-table__column-resize-proxy"
                        style="display: none"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课程表" name="ClassScheduleCard">
          <ClassScheduleCard :student_id="StudentId"></ClassScheduleCard>
        </el-tab-pane>
        <el-tab-pane label="上课记录" name="Classrecord">
          <div style="margin-top: 10px">
            <div
              class="work-title-content flex aligin-items-center flex-wrap-wrap"
            >
              <el-popover width="200" trigger="click">
                <el-checkbox-group
                  @change="class_change"
                  v-model="choice_Class"
                >
                  <el-checkbox
                    class="padding-xs"
                    :label="item.class_name"
                    v-for="(item, index) of Class_List"
                    :key="index"
                  />
                </el-checkbox-group>
                <div slot="reference" style="width: 200px" class="cursor">
                  <span>班级名称</span>
                  <i class="el-icon-caret-bottom" />
                  <span class="border-right" />
                </div>
              </el-popover>
            </div>
            <div class="margin-sm">
              <span>当前结果：总计{{ classRecords_num }}条记录</span>
            </div>
            <as-list
              :ajaxMethods="{ list: 'getArrangeCurriculumList' }"
              :listQueryProp="listQueryStudent"
              @requestChange="Class_recordChange"
            >
              <el-table-column
                height="40"
                align="left"
                sortable
                label="上课日期"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.class_begin_date">
                    <span>{{ scope.row.class_begin_date.split("T")[0] }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                height="40"
                prop="arrange_classes_name"
                label="班级/一对一名称"
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="curriculum_name"
                label="课程"
                height="40"
              >
              </el-table-column>

              <el-table-column
                align="left"
                prop="arrange_theme"
                label="上课类容"
                height="40"
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="arrange_subject_name"
                label="科目"
                height="40"
              >
              </el-table-column>
              <el-table-column
                align="left"
                prop="class_arrival_name"
                label="到课状态"
                height="40"
              >
              </el-table-column>
              <el-table-column align="left" label="到课类型">
                <template slot-scope="scope">
                  <span class="cursor"> {{ scope.row.class_type_name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                height="40"
                prop="valid_class_hour"
                label="扣除课时"
              >
              </el-table-column>
              <el-table-column
                align="left"
                height="40"
                prop="arrange_teacher_name"
                label="教师"
              >
              </el-table-column>
              <el-table-column align="left" label="操作">
                <template slot-scope="scope">
                  <span class="cursor text-blue" @click="gopage(scope.row)"
                    >编辑上课记录</span
                  >
                </template>
              </el-table-column>
            </as-list>
          </div>
        </el-tab-pane>

        <el-tab-pane label="操作日志" name="Operation1">
          <operationLog :student_id="StudentId"></operationLog>
        </el-tab-pane>
      </el-tabs>
    </div>
    <StudentsDivision
      ref="BatchAdjustmentList"
      :classesId="classesId"
      :curriculumId="curriculum_id"
      :Visible="editDivision"
      @RefreshTheCourse="RefreshThe"
      @closepop="closeEditPop"
    >
    </StudentsDivision>
    <StopCourse
      :Visible="editVisible"
      :StopCourse_data="StopCourse_data"
      :ClassEnding="editType === 'ClassEnding'"
      @closepop="closeEditPop"
      :title="titlename"
    ></StopCourse>
  </div>
</template>
<script>
import ClassScheduleCard from "./InformationComponents/ClassScheduleCard";
import AsList from "@/components/as-list";
import operationLog from "./InformationComponents/operationLog";
import StudentsDivision from "../components/StudentsDivision";
import StopCourse from "../components/StopCourse";
export default {
  components: {
    AsList,
    operationLog,
    StudentsDivision,
    StopCourse,
    ClassScheduleCard,
  },
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      curriculum_id: 0,
      editDivision: false,
      isClassEnding: false,
      titlename: "",
      CourseStatus: [], //课程状态
      StopCourse_data: {},
      classesId: 0,
      editType: "",
      editVisible: false,
      isshow: true,
      ClassType: [],
      classRecords_num: 0,
      ArrivalList: [], //获取到课状态枚举
      listQueryStudent: {
        student_id: 5,
        class_ids: [],
        KeyWord: "",
        PageIndex: 1,
        PageSize: 16,
      },
      choice_Class: [], //选择的班级
      classInput: "",
      Class_List: [],
      Arrears: 0, //欠费
      receivable: 0, //应收
      netReceipts: 0, //实收
      Orderparameter: {
        student_id: "",
      },
      OrderList: [], //学员订单
      StudentId: "",
      jobList: 1,
      activeName: "order",
      stuForm: {
        id: "",
        birthday: "", //生日
        source: "", //来源渠道
        FullName: "", //学员姓名
        sex: 1, //
        phone: "",
        relation: "", //关系
        school_admin: "1", //学管员
        wechat_no: "", //微信号
        introducer: "", //介绍人
        id_card: "", //身份证
        nation_name: "", //民族
        public_schools: "", //公立学校
        grade_name: "", //年级
        public_grade: "", //公立学校班级
        high_school: "", //高中学校
        junior_high_school: "", //初中学校
        primary_school: "", //小学学校
        kindergarten: "", //幼儿园
        industry_name: "", //行业
        avatar: "", //头像
      },
      student: [
        // { name: "磁卡卡号", value: "213213123213" },
        {
          name: "学管师",
          field: "school_admin_name",
        },
        {
          name: "微信号",
          field: "wechat_no",
        },
        {
          name: "生日",
          field: "birthday",
        },
        {
          name: "身份证号",
          field: "id_card",
        },
        {
          name: "民族",
          field: "nation_name",
        },
        {
          name: "公立学校",
          field: "public_grade",
        },
        {
          name: "年纪",
          field: "grade_name",
        },
        {
          name: "公立校班级",
          field: "public_grade",
        },
        {
          name: "高中学校",
          field: "high_school",
        },
        {
          name: "初中学校",
          field: "junior_high_school",
        },
        {
          name: "小学学校",
          field: "primary_school",
        },
        {
          name: "幼儿园",
          field: "kindergarten",
        },
        {
          name: "行业",
          field: "industry_name",
        },
        {
          name: "单位名称",
          field: "company_name",
        },
      ],
    };
  },
  created() {
    if (this.$route.query.ClassScheduleCard) {
      this.activeName = this.$route.query.ClassScheduleCard;
    }
    this.StudentId = this.$route.query.id;
    this.listQueryStudent.student_id = this.StudentId;
    this.listQueryStudent = JSON.parse(JSON.stringify(this.listQueryStudent));
    this.Orderparameter.student_id = this.StudentId;
    this.getstudent();
    this.getArrival();
    this.get_ClassList();
    this.GetStudentOrderCurriculums();
  },
  mounted() {},
  watch: {
    editVisible(val) {
      if (!val) {
        this.GetStudentOrderCurriculums();
      }
    },
  },
  methods: {
    RefreshThe() {
      this.GetStudentOrderCurriculums();
    },
    closeEditPop() {
      this.editDivision = false;
      this.editVisible = false;
    },
    goSignUp(row) {
      this.$router.push({
        path: "/workCenter/signUp",
        query: {
          id: row.id,
        },
      });
    },
    //退费跳转
    gorefund(row) {
      this.$router.push({
        path: "/workCenter/refund",
        query: {
          id: row.id,
        },
      });
    },
    //转课
    gotransferCourses(row) {
      this.$router.push({
        path: "/workCenter/transferCourses",
        query: {
          id: row.id,
        },
      });
    },
    selectAllChange(val) {
      this.BatchAdjustmentList = val;
      this.$refs.BatchAdjustmentList.refsh(this.BatchAdjustmentList);
    },
    editHandle(type, id, item) {
      if (type == "Division") {
        this.editDivision = true;
        this.editType = type;
        this.classesId = id;
        this.curriculum_id = item.curriculum_id;
      } else if (type == "StopCourse") {
        this.titlename = "停课";
        this.editVisible = true;
        this.editType = type;
        this.StopCourse_data = id;
      } else if (type == "ClassEnding") {
        this.titlename = "结课";
        this.editVisible = true;
        this.editType = type;
        this.isClassEnding = true;
        this.StopCourse_data = id;
      }
    },
    gopage(row) {
      if (row.arrange_classes) {
        debugger;
        this.$router.push({
          path: "/educationCenter/addRemembertoClass",
          query: {
            classid: row.arrange_classes,
            id: row.arrange_id,
            type: "edit",
          },
        });
      } else {
        this.$message.error("该学员未分配班级");
      }
    },
    Class_recordChange(res) {
      this.classRecords_num = res.total;
    },
    requestChange(res) {
      this.rosterSize = res.total;
    },
    //获取到课状态枚举
    getArrival() {
      this.API.SystemGetSettings("ClassArrival", false).then((res) => {
        this.ArrivalList = res;
      });
      this.API.SystemGetSettings("ClassType", false).then((res) => {
        this.ClassType = res;
      });
      this.API.SystemGetSettings("OrderCurriculumnsStatus", false).then(
        (res) => {
          this.CourseStatus = res;
        }
      );
    },
    class_change() {
      // choice_Class
      var class_ = this.Class_List.filter((rs) => {
        return this.choice_Class.indexOf(rs.class_name) != -1;
      });
      this.listQueryStudent.class_ids = class_.map((rs) => {
        return rs.id;
      });
    },
    //获取学员班级
    get_ClassList() {
      var listQuery = {
        student_id: [],
        PageIndex: 1,
        PageSize: 20,
        KeyWord: "",
      };
      listQuery.student_id = [];
      listQuery.student_id.push(this.StudentId);
      this.API.getClassList(listQuery).then((res) => {
        this.Class_List = res.data.rows;
        // debugger
        // class_name
        // this.Class_List = this.Class_List.filter((rs) => {
        //   return rs.class_name == null;
        // });
        if (this.Class_List && this.Class_List.length) {
          this.listQueryStudent.class_ids = this.Class_List[0].id;
        }
      });
    },
    //获取该学员订单
    GetStudentOrderCurriculums() {
      var id = [];
      id.push(this.StudentId);
      this.API.GetStudentOrderCurriculums(id).then((res) => {
        if (res.success) {
          this.OrderList = res.data;
          this.$refs.OrderList.refsh(this.OrderList);
          if (res.outer_data) {
            this.Arrears = res.outer_data.arreas_money;
            this.netReceipts = res.outer_data.pay_money;
            this.receivable = res.outer_data.total_money;
          }
        }

        // Arrears: 0, //欠费
        // receivable: 0, //应收
        // netReceipts: 0, //实收
        // for (let index = 0; index < this.OrderList.length; index++) {
        //   this.Arrears = this.Arrears + this.OrderList[index].arrears_money;
        //   this.receivable = this.receivable + this.OrderList[index].total_money;
        //   this.netReceipts = this.netReceipts + this.OrderList[index].pay_money;
        // }
        this.$forceUpdate();
        // console.log(this.OrderList);
      });
    },
    delstudent() {
      this.$confirm("此操作将删除该学员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = this.StudentId;
          this.API.ChangeStatus(ids).then((res) => {
            if (res.success) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.getStudenUrl("/educationCenter/Trainee");
            } else {
              this.$message({
                message: "删除失败！",
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // delstudent(){

    // },
    getstudent() {
      this.API.StudentDetail(this.StudentId).then((res) => {
        this.stuForm = res.data;
        this.$forceUpdate();
      });
    },
    handleClick() {},
    creatStudents() {
      var studentEdit = {
        id: this.StudentId,
        title: "编辑学员",
        Edit: true,
      };

      this.$Dialog
        .CreatStudents({
          studentEdit,
        })
        .then((res) => {
          this.getstudent();
          // this.chooseClassResult=res || [];
        });
    },
    getStudenUrl(url) {
      this.$router.push(url);
    },
    job_List(t) {
      this.jobList = t;
    },
  },
};
</script>

<style lang="scss">
.background-f {
  background-color: #fff;
}

.icon {
  width: 30px;
  height: 30px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.b-color {
  color: #ffffff;

  height: 100px;
  background: linear-gradient(
    to bottom,
    #0e82b0 26%,
    #0e82b0 46%,
    white 30%,
    white 100%
  );
}

.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}

.class_name {
  width: 200px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.class_boid {
  font-size: 16px;
  font-weight: bold;
}

.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

.text-name {
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.text-phone {
  font-size: 14px;
  color: #000;
  margin: 0px 5px 0px 5px;
  // color: #cecece;
  // font-weight: bold;
}

>>> .is-active {
  height: 43px;
  font-size: 17px;
  font-weight: bold !important;
}

.text-align {
  text-align: center;
}

.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}

.color-black {
  color: black;
}

.myClient_client_img1 {
  margin-top: 10px;
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
