<template>
  <el-dialog
    :title="titlename"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="30%"
  >
    <div>
      <div v-if="!ClassEnding">
        <div class="text-center padding">
          <span>是否确定停课，停课后将不能对学员记上课？</span>
        </div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="备注"
            ><el-input
              type="textarea"
              placeholder="请输入"
              v-model="ruleForm.remark"
              maxlength="50"
              show-word-limit
            >
            </el-input
          ></el-form-item>
        </el-form>
      </div>
      <div v-else class="text-center padding">
        <span
          >课程结课后，系统将课程的剩余学费、已失效课时自动转成学校的非课消收入。您确认结课？</span
        >
        <!-- 将为【
          {{ StopCourseData.order.student_name }}
          】报读的【
          {{ StopCourseData.classes.class_name }}
          】 -->
      </div>
      <div class="flex justify-content-flexEnd">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button class="myClient-left-30" @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },

    StopCourse_data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ClassEnding: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      titlename: "",
      dialogVisible: false,
      groupNmae: "",
      StopCourseData: [],
      ruleForm: {
        status: 5,
        order_curriculum_id: "",
        remark: "",
      },
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
    title(val) {
      this.titlename = val;
    },
    StopCourse_data: {
      deep: true,
      handler: function (val) {
        this.StopCourseData = val;
        this.ruleForm.order_curriculum_id = val.id;
      },
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit("closepop");
    },
    getList() {
      this.$emit("getList");
    },
    submit() {
      //ChangeOrderCurriculumStatus
      if (!this.ClassEnding) {
        var model = {
          status: 5,
          order_curriculum_id: this.ruleForm.order_curriculum_id,
          remark: this.ruleForm.remark,
        };
        this.API.ChangeOrderCurriculumStatus(model).then((res) => {
          if (res.success) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.close();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        var model = {
          status: 2,
          order_curriculum_id: this.ruleForm.order_curriculum_id,
        };
        this.API.ChangeOrderCurriculumStatus(model).then((res) => {
          if (res.success) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.close();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>

<style></style>
