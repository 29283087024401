<template>
  <div>
    <!-- 时间课表试图 -->
   
    <!-- 日期选择 -->
    <changeDate @dateChange="dateChange" :types="1" class="margin-tb-xl"></changeDate>
    <!-- 日、周、月课表 -->
    <div style="margin-top: 10px">
      <as-list :initdataList="curriculumsComputed" v-if="tabPosition === 'day'" :isPagination="false" :cellStyle="celldiy">
        <el-table-column align="center" prop="period" width="140"></el-table-column>
        <el-table-column align="center" prop="currentDateFilter[currentDate]" :label="`星期 ${currentDate}`">
          <template slot-scope="scope">
            <div   class="margin-left-xs" v-for="info in scope.row['course' + currentDate].curriculumns" :key="info.id">
              <el-popover width="220" trigger="hover" :visible-arrow="false">
                <div>
                  <div>开始：{{ info.class_begin_date | dateTimeFilter('yyyy-MM-dd') }} {{ info.class_begin_time_start }}</div>
                  <div>结束：{{ info.class_begin_date | dateTimeFilter('yyyy-MM-dd') }} {{ info.class_begin_time_end }}</div>
                  <div>教室：{{ info.class_room_name }}</div>
                  <div>班课/一对一：{{ info.arrange_classes_name }}</div>
                  <div>教师：{{ info.arrange_teacher_name }}</div>
                  <div>科目：{{ info.arrange_subject_name }}</div>
                  <div>实到/应到：{{info.Population}}</div>
                </div>
                <div slot="reference" class="padding-xs border-radius" :class="[info.Isuse === 0 ? 'bg-blue' : 'bg-grey',info.Is_Conflict ? 'educationCenter-olorCode-c' : '']" style="min-width: 200px;text-align: left;">
                  <div class="text-cut">
                    <i class="el-icon-c-scale-to-original text-white el-icon--left" />
                    {{ info.arrange_classes_name }}
                  </div>
                  <div>
                    <div>
                      <i class="el-icon-user text-white el-icon--left" />
                      {{ info.arrange_teacher_name }}
                    </div>
                    <div>
                      <i class="el-icon-location-outline text-white el-icon--left" />
                      {{ info.class_room_name }}
                    </div>
                    <div>
                      <span style="border: 1px solid #FFFFFF;font-size: 8px;padding: 1px;">科</span>
                      {{ info.arrange_subject_name }}
                    </div>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </as-list>

      <as-list :initdataList="curriculumsComputed" v-if="tabPosition === 'week' && weekValue === 1" :isPagination="false">
        <el-table-column align="center" prop="period" width="100"></el-table-column>
        <template v-for="(item, index) in currentDateFilter">
          <el-table-column :label="`周${index + 1} ${item}`" >
            <template slot-scope="scope" v-if="scope.row['course' + (index + 1)].curriculumns.length > 0">
              <div
                class="text-left"
                :class="{ 'margin-top-xs': index > 1 }"
                v-for="(info, infoIndex) in scope.row['course' + (index + 1)].curriculumns"
                :key="info.id"
              >
                <el-popover width="220" trigger="hover" :visible-arrow="false">
                  <div>
                    <div>开始：{{ info.class_begin_date | dateTimeFilter('yyyy-MM-dd') }} {{ info.class_begin_time_start }}</div>
                    <div>结束：{{ info.class_begin_date | dateTimeFilter('yyyy-MM-dd') }} {{ info.class_begin_time_end }}</div>
                    <div>教室：{{ info.class_room_name }}</div>
                    <div>班课/一对一：{{ info.arrange_classes_name }}</div>
                    <div>教师：{{ info.arrange_teacher_name }}</div>
                    <div>科目：{{ info.arrange_subject_name }}</div>
                    <div>实到/应到：{{info.Population}}</div>
                  </div>
                  <div slot="reference" class="padding-xs border-radius" :class="[info.Isuse === 0 ? 'bg-blue' : 'bg-grey',info.Is_Conflict ? 'educationCenter-olorCode-c' : '']">
                    <div class="text-cut">
                      <i class="el-icon-c-scale-to-original text-white el-icon--left" />
                      {{ info.arrange_classes_name }}
                    </div>
                    <div v-if="scope.row['course' + (index + 1)].curriculumns.length < 2">
                      <div>
                        <i class="el-icon-user text-white el-icon--left" />
                        {{ info.arrange_teacher_name }}
                      </div>
                      <div>
                        <i class="el-icon-location-outline text-white el-icon--left" />
                        {{ info.class_room_name }}
                      </div>
                      <div>
                        <span style="border: 1px solid #FFFFFF;font-size: 8px;padding: 1px;">科</span>
                        {{ info.arrange_subject_name }}
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </template>
      </as-list>
      <el-calendar v-model="startDate" v-if="tabPosition === 'month'">
        <template slot="dateCell" slot-scope="{ date, data }">
          <span class="flex justify-content-flexEnd">{{ date.day }} {{ data.day.slice(8, 10) }}</span>
          <div v-for="item in curriculumRecords">
            <el-popover width="220" trigger="hover" :visible-arrow="false">
              <div>
                <div>开始：{{ item.class_begin_date | dateTimeFilter('yyyy-MM-dd') }} {{ item.class_begin_time_start }}</div>
                <div>结束：{{ item.class_begin_date | dateTimeFilter('yyyy-MM-dd') }} {{ item.class_begin_time_end }}</div>
                <div>教室：{{ item.class_room_name }}</div>
                <div>班课/一对一：{{ item.arrange_classes_name }}</div>
                <div>教师：{{ item.arrange_teacher_name }}</div>
                <div>科目：{{ item.arrange_subject_name }}</div>
                <div>实到/应到：{{item.Population}}</div>
              </div>
              <div  slot="reference" class="margin-top-xs padding-xs border-radius" :class="[item.Isuse === 0 ? 'bg-blue' : 'bg-grey',item.Is_Conflict ? 'educationCenter-olorCode-c' : '']" v-if="item.class_begin_date.slice(0, 10) == data.day">
                <div>{{ item.class_begin_time_start }}-{{ item.class_begin_time_end }}</div>
                <div>{{ item.arrange_teacher_name }}_{{ item.arrange_classes_name }}</div>
              </div>
            </el-popover>
          </div>
        </template>
      </el-calendar>
    </div>
    <!-- 课程表弹窗 -->
    <el-dialog title="课程表" :visible.sync="dialogTableVisible" width="35%">
      <div class="flex flex-direction margin-left-50">
        <el-form :model="chooseData" status-icon label-width="100px" class="demo-ruleForm">
          <el-form-item label="上课时间：" >
            <span>{{ chooseData.class_begin_date | dateTimeFilter('yyyy-MM-dd') }} {{ chooseData.class_begin_time_start }}-{{ chooseData.class_begin_time_end }}</span>
          </el-form-item>
          <el-form-item label="教室：" >
            <span>{{ chooseData.class_room_name }}</span>
          </el-form-item>
          <el-form-item label="班课/一对一：" >
            <span>{{ chooseData.arrange_classes_name }}</span>
          </el-form-item>
          <el-form-item label="教师：" >
            <span>{{ chooseData.arrange_teacher_name }}</span>
          </el-form-item>
          <el-form-item label="科目：" >
            <span>{{ chooseData.arrange_subject_name }}</span>
          </el-form-item>
        </el-form>
        <div>实到/应到：{{chooseData.Population}}</div>
      </div>
      <div slot="footer" class="border-top-grey padding-top">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button @click="edit(chooseData)">编辑单次</el-button>
        <el-button @click="del(chooseData.id)">删 除</el-button>
        <el-button type="primary" @click="goNav(chooseData)">编辑上课</el-button>
      </div>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog
      title="删除排课"
      :visible.sync="delVisible"
      width="30%">
      <span>是否确认删除该排课</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureDel()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import changeDate from '../../components/changeDate.vue';
import { formatTime } from '@/utils/util.js';
export default {
  components: {
    changeDate
  },
  data() {
    return {
      queryList: {
        KeyWord: '', 
        subject_id: [2007], 
        today: false,
        PageIndex: 1,
        PageSize: 10
      },
      isShow: false,
      weekValue: 1,
      
      dates: [],
      tabPosition: 'week',
      orderSatus: ['待支付', '已完成'], // 订单状态
      curriculumRecords: [],
      curriculumsComputed: [],
      startDate: '',
      nowDay: '',
      // 筛选
      listQuery1: {
        HasRole: false,
        module: '',
        PageIndex: 1,
        PageSize: 10
      },
      schoolList: [],
      courseClassList: [],
      SubjectTypeList: [],
      teacherList: [],
      classroomList: [],
      chooseData: {},
      dialogTableVisible: false,
      delVisible: false,
      delId: ''
    };
  },
  created() {
    this.getList(); 
  },
  watch: {
    curriculumRecords: {
      deep: true,
      handler() {
        this.curriculumsComputed1();
      }
    },
    dates: {
      deep: true,
      handler() {
        this.curriculumsComputed1();
      }
    },
    queryList: {
      deep: true,
      handler: function(val) {
        this.queryList = val;
        this.getList();
      }
    }
  },
  computed: {
    currentDateFilter() {
      return this.dates.map(t => {
        return formatTime(t, 'MM月dd日');
      });
    }
  },
  methods: {
    curriculumsComputed1() {
      let dates = this.dates;
      let list = [];
      this.curriculumsComputed = [];
      for (let i = 7; i <= 20; i++) {
        let item = {
          period: i,
          course1: {
            date: dates[0],
            curriculumns: this.dataFilter(dates[0], i)
          },
          course2: {
            date: dates[1],
            curriculumns: this.dataFilter(dates[1], i)
          },
          course3: {
            date: dates[2],
            curriculumns: this.dataFilter(dates[2], i)
          },
          course4: {
            date: dates[3],
            curriculumns: this.dataFilter(dates[3], i)
          },
          course5: {
            date: dates[4],
            curriculumns: this.dataFilter(dates[4], i)
          },
          course6: {
            date: dates[5],
            curriculumns: this.dataFilter(dates[5], i)
          },
          course7: {
            date: dates[6],
            curriculumns: this.dataFilter(dates[6], i)
          }
        };
        this.curriculumsComputed.push(JSON.parse(JSON.stringify(item)));
      }
      this.curriculumsComputed.map(t => {
        if (t.period < 12) t.period = '早上' + '' + t.period + ':00';
        if (t.period == 12) t.period = '中午' + '' + t.period + ':00';
        if (t.period > 12) t.period = '下午' + '' + t.period + ':00';
      });
      return list;
    },
    dataFilter(dates, i) {
      let val = this.curriculumRecords.filter(t => {
        var flag =
          t.class_begin_time_start &&
          t.class_begin_date &&
          formatTime(new Date(t.class_begin_date), 'yyyy-MM-dd') == formatTime(new Date(dates), 'yyyy-MM-dd') &&
          Number(t.class_begin_time_start.split(':')[0]) === i;
        return flag;
      });
      return val;
    },
    getList() {
      this.API.queryTimeList(this.queryList).then(res => {
        this.curriculumRecords = res.data.rows;
        console.log(this.curriculumRecords);
      });
    },
    // 设置日表格样式
    celldiy({ rowIndex, columnIndex }) {
      if (rowIndex > 0 && columnIndex == 1) return 'celldiy1';
    },
    setDate(date, dayCount) {
      return new Date(date.getTime() + 1000 * 60 * 60 * 24 * dayCount);
    },
    dateChange(res) {
      this.tabPosition = res.type;
      let startDate = res.date[0];
      let date = formatTime(res.date[2], 'yyyy-MM-dd');
      // if (this.tabPosition == 'day') (this.queryList.class_begin_date = date + '~' + date), this.getList();
      this.startDate = startDate;
      let endDate = res.date[1];
      let currentDay = res.date[2];
      this.weekValue = res.date[3];
      if (currentDay.getDay() == 0) {
        this.currentDate = 7;
      } else {
        this.currentDate = currentDay.getDay();
        console.log(this.currentDate);
      }
      this.dates = [];
      while (startDate <= endDate) {
        this.dates.push(startDate);
        startDate = this.setDate(startDate, 1);
      }
    },
    // 选中单元格
    // cellClick(e) {
    //   if(e.Isuse === 0) {
    //     this.dialogTableVisible = true
    //     this.chooseData = e
    //   }else {
    //     this.$message.error('已记上课的日程，不可编辑单次')
    //   }
    // },
    // 编辑上课
    // 删除 updatearrangeCurriculumStatus
    del(id) {
      this.dialogTableVisible = false
      this.delVisible = true
      this.delId = id
    },
    // 确认删除
    sureDel() {
      const delData = {
        ids: this.delId,
        status: -99
      }
        this.API.updatearrangeCurriculumStatus(delData).then(res => {
          if(res.code == 200) {
            this.$message.success(res.message)
            this.delVisible = false
          }else {
            this.$message.error(res.message)
          }
        })
    },
  }
};
</script>

<style scoped>
>>> .el-calendar-day.el-calendar-day {
  min-height: 50px;
  height: auto !important;
}
>>> .celldiy1 .cell {
  display: flex;
}
</style>
