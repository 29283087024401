<template>
  <div class="total"> 
    <as-list
      ref="studentListAsList"
      :ajaxMethods="{ list: 'QueryUserLog' }"
      :listQueryProp="query"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column 
        height="40"
        prop="user_name"
        label="操作人"
      >
      </el-table-column>
      <el-table-column 
        height="40"
        prop="TypeName"
        label="操作类型"
      >
      </el-table-column>
      <el-table-column 
        height="40"
        prop="Description"
        label="操作内容"
      >
      </el-table-column>
    </as-list>
  </div>
</template>
<script>
import AsList from "@/components/as-list";

export default {
  props: {
    student_id: {
      type: String,
      default: "",
    },
  },
  components: {
    AsList,
  },
  data() {
    return {
      query: {
        // type: [2],
        // student_id: [5],
        // school_id: [1],
        student_id: [this.student_id],
        skip_current_school: true,
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
    };
  },
  watch: {
    student_id: {
      deep: true,
      handler(val) {
        this.query.student_id = val;
      },
    },
    
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  computed: {},

  created() {},
  methods: {},
};
</script>

<style  scoped>
>>> el-table-column {
  height: 40px;
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.work-title-content div {
  padding: 0 5px 0 0px;
}
.total {
  width: 100%;
}
.shool {
  margin: 0px 10px;
}
.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

>>> .el-select .el-input {
  width: 110px !important;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.el-select .el-input {
  width: 105px;
}
</style>
